import {Divider} from "../common/Divider";
import {RemixIcon} from "../common/RemixIcon";
import {Flex} from "../common/Flex";
import {Expanded} from "../common/Expanded";
import {Card} from "../common/Card";
import {Dimens} from "../../utils/defaults";
import {Avatar} from "../common/Avatar";
import {Link} from "react-router-dom";

export const UserPopup = () => {

    const performLogout = async (e: any) => {
        e.preventDefault();
    }


    return (
        <Card innerPadding={Dimens.dimenM}>
            <Flex gap={"normal"} className={"account-header"}>
                <Avatar size={"small"}>JD</Avatar>
                <Expanded>
                    <h6>John Doe</h6>
                    <p className={"text-caption text-sm"}>john@educare.com</p>
                </Expanded>
            </Flex>
            <Divider/>
            <div className={"dropdown-items"}>
                <Link className={"flex flex-middle gap-normal"} to={""}>
                    <RemixIcon name={"user-3"}/>
                    <Expanded>Profile</Expanded>
                </Link>
                <Link to={""} className={"flex flex-middle gap-normal"} onClick={performLogout}>
                    <RemixIcon name={"logout-box"}/>
                    <Expanded>Logout</Expanded>
                </Link>
            </div>
        </Card>
    );
}
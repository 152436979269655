import "../../css/Loader.css";

export type LoaderProps = {
    color?: "white" | "gray" | "black"
    placement?: "normal" | "inner" | "wrap"
    size?: "tiny" | "small" | "normal" | "large"
}
export const BallLoader = ({placement = "normal"}: LoaderProps) => {
    return (
        <div className={"loading-spinner-container " + placement}>
            <div className="loading-spinner">
                <div className="balls">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export const Loader = ({placement = "normal", size, color}: LoaderProps) => {
    return (
        <div
            className={"loading-spinner-container " + placement + (size ? " " + size : "") + (color ? " " + color : "")}>
            <div className={"logo-spinner"}>
                <div className={"logo-top"}></div>
                <div className={"logo-bottom"}></div>
            </div>
        </div>
    );
}
import {ReactNode} from "react";
import {Size} from "../../utils/types";

export type AvatarProps = {
    children: ReactNode;
    size?: Size;
    className?: string;
    background?: "canvas" | "normal" | "invert";
    borderLess?: boolean;
}
export const Avatar = ({children, borderLess, size, className, background}: AvatarProps) => {
    let cls = ["avatar"];
    if (size) cls.push(size);
    if (className) cls.push(className);
    if (background) cls.push(background);
    if (borderLess) cls.push("border-less");
    return (
        <div className={cls.join(" ")}>{children}</div>
    )
}
import "../../css/Pagination.css"
import {Flex} from "./Flex";
import {IconedSelect} from "./IconedSelect";
import {RemixIcon} from "./RemixIcon";
import {updateUrlParam} from "../../utils/utils";

export type PaginatorProps = {
    current: number;
    perPage: number;
    totalItems: number;
    onPageChanged: (page: number) => void;
    onPerPageChanged: (size: number) => void;
}
export const Paginator = ({totalItems, perPage, current, onPageChanged, onPerPageChanged}: PaginatorProps) => {
    let total = Math.ceil(totalItems / perPage);
    let pages: number[];
    let ellipsisStart = false;
    let ellipsisEnd = false;
    if (total <= 5) {
        pages = Array.from({length: total}).map((_, index) => index + 1);
    } else if (current === 1) {
        pages = [current + 1, current + 2];
        ellipsisEnd = true;
    } else if (current === 2) {
        pages = [current, current + 1];
        ellipsisEnd = true;
    } else if (current === 3) {
        pages = [current - 1, current, current + 1];
        ellipsisEnd = true;
    } else if (current === total) {
        pages = [current - 2, current - 1];
        ellipsisStart = true;
    } else if (current === total - 1) {
        pages = [current - 1, current];
        ellipsisStart = true;
    } else if (current === total - 2) {
        pages = [current - 1, current, current + 1];
        ellipsisStart = true;
    } else {
        pages = [current - 1, current, current + 1];
        ellipsisStart = true;
        ellipsisEnd = true;
    }

    const _onPageChanged = (value: number) => {
        onPageChanged(value);
        window.history.replaceState(null, "", updateUrlParam("page", `${value}`));
    }
    const _onPerPageChanged = (value: number) => {
        onPageChanged(1);
        onPerPageChanged(value);
        window.history.replaceState(null, "", updateUrlParam("per_page", `${value}`));
        window.history.replaceState(null, "", updateUrlParam("page", "1"));
    }

    let start = (current - 1) * perPage;
    let end = start + perPage;


    return (
        <Flex wrap={true} horizontalAlign={"space-between"}>
            <Flex>
                <IconedSelect
                    onChange={(value) => _onPerPageChanged(value.value)}
                    defaultValue={{title: `${perPage}`, value: perPage}}
                    labelText={""} defaultItems={[
                    {title: "5", value: 5},
                    {title: "10", value: 10},
                    {title: "20", value: 20},
                    {title: "30", value: 30},
                    {title: "40", value: 40},
                    {title: "50", value: 50},
                ]}/>
                <span className={"text-caption"}>items per page</span>

            </Flex>
            <span
                className={"text-caption mr-m"}>{start + 1} - {end} of {totalItems} items</span>

            <div className={"pagination"}>
                <button
                    key={"page-prev"}
                    onClick={() => _onPageChanged(current - 1)}
                    className={current === 1 ? "disabled" : ""}>
                    <RemixIcon name={"skip-left"}/>
                </button>
                {total > 5 ?
                    <>
                        <button
                            onClick={() => _onPageChanged(1)}
                            key={"page-1"}
                            className={current === 1 ? "active" : ""}>1
                        </button>
                        {ellipsisStart && <span>...</span>}
                        {pages.map((item) => (
                            <button
                                onClick={() => _onPageChanged(item)}
                                key={"page-" + item}
                                className={current === item ? "active" : ""}>{item}</button>
                        ))}
                        {ellipsisEnd && <span>...</span>}
                        <button
                            onClick={() => _onPageChanged(total)}
                            key={"page-" + total}
                            className={current === total ? "active" : ""}>{total}</button>
                    </> : pages.map((item) => (
                        <button
                            onClick={() => _onPageChanged(item)}
                            key={"page-" + item}
                            className={current === item ? "active" : ""}>{item}</button>
                    ))
                }

                <button
                    onClick={() => _onPageChanged(current + 1)}
                    key={"page-next"}
                    className={current >= total ? "disabled" : ""}>
                    <RemixIcon name={"skip-right"}/>
                </button>
            </div>
        </Flex>
    );
}
import React from "react";
import {Flex} from "../common/Flex";

export const SidebarSectionTitle = ({title}: { title: string }) => {
    return (
        <Flex className={"mt-m"}>
            <div className={"sidebar-section-divider"}/>
            <h6 className={"sidebar-section-title"}>{title}</h6>
        </Flex>
    );
}
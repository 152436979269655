import {useEffect, useState} from "react";
import {DataTable, DataTableStateEvent} from "primereact/datatable";
import {Column, ColumnBodyOptions} from "primereact/column";
import {useHeader} from "../../context/HeaderProvider";
import {useI18n} from "../../context/LocaleProvider";
import {I18n} from "../../i18n/I18n";
import {Paginator} from "../../components/common/Paginator";
import {Columns} from "../../utils/columns";
import {SortAsc, SortDesc, SortNone} from "../../components/icons/SortIcons";
import {RemixIcon} from "../../components/common/RemixIcon";
import {Button} from "../../components/common/Button";
import {Card} from "../../components/common/Card";
import {Flex} from "../../components/common/Flex";
import {IconedInput} from "../../components/common/IconedInput";
import {Link} from "react-router-dom";
import {Loader} from "../../components/common/Loader";
import {debounceDelay, pageSize} from "../../utils/defaults";
import {debounce, getIntParam, getSort, getSortFromQuery, updateUrlParam} from "../../utils/utils";
import {InstitutionListResponse} from "../../models/institution_list_response";
import {Alert} from "../../components/common/Alert";
import {Tooltip} from "../../components/common/Tooltip";
import {InstitutionsMenu} from "../InstitutionsMenu";
import {InstitutionRepo} from "../../repo/institution_repo";
import {useAuth} from "../../context/AuthProvider";
import {APILoadState, ListAPIResponse, SortProps} from "../../models/common";

export const Institutions = () => {

    const [page, setPage] = useState(getIntParam("page"));
    const [perPage, setPerPage] = useState(getIntParam("per_page", pageSize));
    const [query, setQuery] = useState("");
    const [sortBy, setSortBy] = useState<SortProps>({sortBy: "", sortOrder: 0});
    const [state, setState] = useState<APILoadState<ListAPIResponse<InstitutionListResponse>>>({status: "idle"});

    const [data, setData] = useState<InstitutionListResponse[]>([]);
    const {setTitle} = useHeader();
    const i18n = I18n[useI18n().locale];

    let {authToken, logout} = useAuth();

    useEffect(() => {
        setTitle(i18n.sidebar.institutions);
        let sort = getSortFromQuery();
        if (sort) setSortBy(sort);
    }, [setTitle, i18n]);

    useEffect(() => {
        const getData = () => {
            setState({status: "loading"});
            let params = {
                page: `${page}`,
                query: query,
                sort: getSort(sortBy),
                per_page: `${perPage}`,
            }
            new InstitutionRepo().getInstitutions(authToken!, params).then(res => {
                setState({status: "error", data: res});
                setData(res.items);
            }).catch(err => {
                if (err?.code === 401) {
                    logout();
                }
                setState({status: "error", error: err});
            });
        }
        getData();
    }, [page, query, sortBy, perPage, authToken]);


    const updateItem = (item: InstitutionListResponse) => {
        setData(prevState => prevState.map(e => e.id === item.id ? item : e));
    }

    const discardItem = (item: InstitutionListResponse) => {
        setData(prevState => prevState.filter(e => e.id !== item.id));
    }
    const _getBody = (data: InstitutionListResponse, options: ColumnBodyOptions): any => {
        if (options.field === "actions") return <InstitutionsMenu onEdit={discardItem} onStatusChanged={updateItem}
                                                                  item={data}/>;
        if (options.field === "mobile") return <Tooltip tooltip={i18n.call}>
            <Link className={"action-link alt"} to={"tel:" + data.phone}>{data.phone}</Link>
        </Tooltip>;
        if (options.field === "status") return <Flex>
            <Alert type={"warning"} text={"Pending"}/>
        </Flex>;
        let field = options.field as keyof InstitutionListResponse;
        if (options.field === "name") {
            return <Link
                to={""}
                className={"action-link"}>
                {data.name}
            </Link>
        }
        return data[field];
    }

    const updateQuery = (value: string) => {
        setPage(1);
        setQuery(value);
        window.history.replaceState(null, "", updateUrlParam("page", "1"));
        window.history.replaceState(null, "", updateUrlParam("query", value));
    }

    const onSort = (event: DataTableStateEvent) => {
        let sort = event.sortField;
        let sortOrder = event.sortOrder;
        setSortBy({sortBy: sort, sortOrder: sortOrder});
        if (sortOrder === -1) sort = "-" + sort;
        window.history.replaceState(null, "", updateUrlParam("sort", sort));
    }

    return <div>
        <Card className={"table"}>
            <Flex horizontalAlign={"space-between"} className={"p-l"}>
                <IconedInput
                    onChange={debounce(updateQuery, debounceDelay)}
                    icon={<RemixIcon name={"search"}/>} hint={i18n.search}/>
                <Button text={"Register"} leading={<RemixIcon name={"quill-pen"}/>}/>
            </Flex>
            <DataTable
                removableSort loadingIcon={<Loader placement={"inner"}/>}
                loading={state.status === "loading"}
                unstyled={false}
                value={data}
                onSort={onSort}
                sortIcon={(options) => {
                    let order = options.sortOrder;
                    if (order === 1) return <SortAsc/>;
                    if (order === -1) return <SortDesc/>;
                    return <SortNone/>;
                }}
                sortField={(sortBy).sortBy}
                sortOrder={(sortBy).sortOrder}
                columnResizeMode={"fit"}
                size={"normal"}>
                {Columns.institutions(i18n).map(e =>
                    <Column body={_getBody} key={e.field}
                            sortable={e.sort}
                            field={e.field} header={e.name}/>)}
            </DataTable>
            <div className={"p-l"}>
                <Paginator
                    onPageChanged={setPage}
                    onPerPageChanged={setPerPage}
                    perPage={perPage}
                    current={page} totalItems={state.data?.pagination.total_count ?? 0}/>
            </div>
        </Card>


    </div>;
}

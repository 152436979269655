import {CSSProperties, ReactNode} from "react";

export type CardProps = {
    style?: CSSProperties
    innerPadding?: number
    children: ReactNode
    className?: string
}
export const Card = ({style, className, innerPadding, children}: CardProps) => {
    let innerStyle: CSSProperties = {};
    if (innerPadding) {
        innerStyle = {padding: innerPadding + "px"};
    }
    return (
        <div style={style} className={"card " + className}>
            <div style={innerStyle}>
                {children}
            </div>
        </div>
    );
}
import {Size, State} from "../../utils/types";
import {Flex} from "./Flex";
import {typeMap} from "../../utils/defaults";

export type AlertProps = {
    type: State;
    text: string;
    size?: Size;
    className?: string;
}
export const Alert = ({type, text, size, className}: AlertProps) => {

    let cls = ["alert flex flex-middle", type];
    if (size) {
        cls.push(size);
    }
    if (className) cls.push(className);
    return (
        <Flex className={cls.join(" ")}>
            {typeMap[type]}
            <span>{text}</span>
        </Flex>
    );
}
import {Role, User} from "../models/user";

abstract class LocalImpl {
    abstract getAuthToken(): string | null;

    abstract getUser(): User | null;

    abstract getRoles(): Role[];

}

const AUTH_TOKEN = "eduhub-admin:auth_token";
const AUTH_USER = "eduhub-admin:user";
const AUTH_ROLES = "eduhub:roles";

export class Local extends LocalImpl {
    setAuthInfo(value?: any): void {
        if (value) {
            localStorage.setItem(AUTH_TOKEN, value.token);
            localStorage.setItem(AUTH_USER, JSON.stringify(value.claims.user));
            localStorage.setItem(AUTH_ROLES, JSON.stringify(value.claims.roles));
        } else {
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(AUTH_USER);
            localStorage.removeItem(AUTH_ROLES);
        }
    }

    getAuthToken(): string | null {
        return localStorage.getItem(AUTH_TOKEN);
    }

    getUser(): User | null {
        let data = localStorage.getItem(AUTH_USER);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    getRoles(): Role[] {
        let data = localStorage.getItem(AUTH_ROLES);
        if (data) {
            return JSON.parse(data);
        }
        return [];
    }

}

import "../../css/Header.css"
import React, {useRef} from "react";
import {UserPopup} from "./UserPopup";
import {RemixIcon} from "../common/RemixIcon";
import {OverlayPanel} from "primereact/overlaypanel";
import {Avatar} from "../common/Avatar";
import {Flex} from "../common/Flex";
import {useHeader} from "../../context/HeaderProvider";

export type HeaderProps = {
    onMenuTap: () => void
}

export const Header = ({onMenuTap}: HeaderProps): React.JSX.Element => {
    const expandAccount = (e: any) => {
        userRef.current?.toggle(e);
    }

    const {title} = useHeader();
    const userRef = useRef<OverlayPanel>(null);

    return (
        <header>
            <div className={"header-container"}>
                <div>
                    <Flex>
                        <button onClick={onMenuTap} className={"btn-transparent btn-menu"}><
                            RemixIcon name={"menu"}/>
                        </button>
                        <h4 className={"ellipsis"}>{title}</h4>
                    </Flex>
                    <Flex gap={"xsmall"}>
                        <button className={"btn-transparent"}>
                            <Avatar borderLess={true} ><RemixIcon name={"notification"}/></Avatar>
                        </button>
                        <button onClick={expandAccount}
                                className={"btn-transparent"}>
                            <Avatar><RemixIcon name={"user-3"}/></Avatar>
                        </button>
                        <OverlayPanel ref={userRef}><UserPopup/></OverlayPanel>
                    </Flex>

                </div>
            </div>
        </header>
    );
}
import {JSX, ReactNode, useRef} from "react";
import {renderToString} from "react-dom/server";

export type TooltipProps = {
    children: ReactNode;
    tooltip: string | JSX.Element;
    className?: string;
}
export const Tooltip = ({children, tooltip, className}: TooltipProps) => {
    const tooltipRef = useRef<HTMLDivElement>(null);

    const onHover = (e: any) => {
        let item = document.createElement("div");
        item.classList.add("tooltip");
        if (className) item.classList.add(className);
        if (typeof tooltip !== "string") tooltip = renderToString(tooltip);
        item.innerHTML = tooltip;
        document.body.appendChild(item);

        let x = e.clientX - (item.clientWidth / 2);
        let y = e.clientY - item.clientHeight - 8;
        if (x < 0) x = 0;
        item.style.top = y + "px";
        item.style.left = x + "px";
        item.remove();
        let existing = document.querySelector(".tooltip");
        if (existing) existing.remove();
        document.body.appendChild(item);
    }

    return (
        <div onMouseOver={onHover} onMouseOut={() => {
            document.querySelector(".tooltip")?.remove();
        }} ref={tooltipRef}>{children}</div>
    );
}
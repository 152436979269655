import {CSSProperties, ReactNode} from "react";

/***
 * Expanded => expands the child to fill the remaining space
 ***/
export type ExpandedProps = {
    children?: ReactNode
    className?: string
    style?: CSSProperties
}
export const Expanded = ({children, className, style}: ExpandedProps) => {
    return (<div style={style} className={"flex-fill" + (className ? " " + className : "")}>{children}</div>);
}
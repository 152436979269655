import {Flex} from "../components/common/Flex";
import {Card} from "../components/common/Card";
import {SectionTitle} from "../components/common/SectionTitle";
import {ReactNode} from "react";
import {RemixIcon} from "../components/common/RemixIcon";
import {useI18n} from "../context/LocaleProvider";
import {I18n} from "../i18n/I18n";
import "../css/Settings.css";
import {Brightness, useTheme} from "../context/ThemeProvider";

export const Settings = () => {
    const {locale} = useI18n();
    let i18n = I18n[locale];
    let {brightness, setBrightness} = useTheme();
    const onThemeChange = (brightness?: Brightness) => {
        setBrightness(brightness);
    }

    return (
        <Card>
            <div className={"p-l"}>
                <SectionTitle title={i18n.theme}/>
                <Flex wrap={true} className={"mt-l"}>
                    <SettingsItem selected={brightness === undefined} onClick={() => onThemeChange()}
                                  icon={<RemixIcon name={"contrast"}/>}
                                  text={i18n.systemDefault}/>
                    <SettingsItem selected={brightness === "light"} onClick={() => onThemeChange("light")}
                                  icon={<RemixIcon name={"sun"}/>} text={i18n.light}/>
                    <SettingsItem selected={brightness === "dark"} onClick={() => onThemeChange("dark")}
                                  icon={<RemixIcon name={"moon"}/>} text={i18n.dark}/>
                </Flex>

            </div>
        </Card>
    );
}
type SettingsItemProps = {
    icon: ReactNode;
    text: string;
    selected?: boolean;
    onClick?: () => void;
}
const SettingsItem = ({icon, text, selected, onClick}: SettingsItemProps) => {
    return (
        <button onClick={onClick} className={"settings-item" + (selected ? " active" : "")}>
            <span className={"settings-item-icon"}>{icon}</span>
            <span className={"settings-item-text"}>{text}</span>
        </button>
    );
}
import {ListAPIResponse} from "../models/common";
import {DashboardResponse} from "../models/dashboard_response";
import {InstitutionListResponse} from "../models/institution_list_response";
import {Networking} from "../utils/networking";
import {Endpoints} from "./repo_config";
import {authHeader} from "../utils/utils";


abstract class InstitutionRepoImpl {
    abstract getDashboard(token: string): Promise<DashboardResponse>;

    abstract getInstitutions(token: string, params: {
        [key: string]: string
    }): Promise<ListAPIResponse<InstitutionListResponse>>;
}

export class InstitutionRepo extends InstitutionRepoImpl {
    getDashboard(token: string): Promise<DashboardResponse> {
        return new Networking().get(Endpoints.dashboard, {});
    }

    getInstitutions(token: string, params: {
        [key: string]: string;
    }): Promise<ListAPIResponse<InstitutionListResponse>> {
        return new Networking().get(Endpoints.institutions, {
            queryParameters: params,
            headers: authHeader(token),
        });
    }

}

import {ReactNode} from "react";
import "../../css/Form.css"

export type IconedInputProps = {
    icon: ReactNode;
    hint: string;
    type?: "text" | "number";
    onChange?: (value: string) => void;
    expand?: boolean;
}
export const IconedInput = ({icon, hint, expand, type = "text", onChange}: IconedInputProps) => {
    return <div className={"iconed-input" + (expand ? " fill" : "")}>
        <span className={"icon"}>{icon}</span>
        <input type={type} onInput={(e) => {
            if (onChange) onChange(e.currentTarget.value);
        }} placeholder={hint}/>
    </div>;
}
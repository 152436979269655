import "../../css/Indicator.css";
import {CSSProperties} from "react";
import {Size} from "../../utils/types";

export type IndicatorProps = {
    size?: Size;
    invisible?: boolean;
    style?: CSSProperties;
}
export const Indicator = ({size = "normal", style, invisible}: IndicatorProps) => {
    let cls: string[] = ["indicator", size];
    if (invisible) {
        cls.push("invisible");
    }
    return (<div style={style} className={cls.join(" ")}></div>)
}
import {Dimens} from "../../utils/defaults";
import {Card} from "../common/Card";
import {Flex} from "../common/Flex";
import {Expanded} from "../common/Expanded";
import {RemixIcon} from "../common/RemixIcon";

export type StatsCardProps = {
    title: string;
    figure: string;
    icon: string;
    color?: string;
}
export const StatsCard = ({
                              title,
                              figure,
                              icon,
                              color,
                          }: StatsCardProps) => {
    let style = {};
    if (color) style = {background: color};
    return (
        <Card style={style} innerPadding={Dimens.dimenL}>
            <Flex>
                <Expanded>
                    <h3 className={"mt-m"}>{figure}</h3>
                </Expanded>
                <RemixIcon size={"large"} name={icon}/>
            </Flex>
            <p>{title}</p>
        </Card>
    );
}
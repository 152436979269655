import {ReactNode} from "react";

type Alignment = "space-between" | "space-around";
type VerticalAlign = Alignment | "middle" | "top" | "bottom";
type HorizontalAlign = Alignment | "center" | "start" | "end";
type Gap = "xsmall" | "small" | "normal" | "large";
export type FlexProps = {
    children: ReactNode;
    shrink?: boolean;
    wrap?: boolean;
    gap?: Gap;
    isVertical?: boolean;
    verticalAlign?: VerticalAlign;
    horizontalAlign?: HorizontalAlign;
    className?: string;
}
export const Flex = (props: FlexProps) => {
    const {
        children,
        shrink,
        isVertical,
        wrap,
        gap = "small",
        verticalAlign = "middle",
        horizontalAlign = "start",
        className,
    } = props;
    let cls = ["flex"];
    if (shrink) cls = ["i-flex"];
    if (wrap) cls.push("flex-wrap");
    if (isVertical) cls.push("flex-col");
    cls.push("gap-" + gap);
    cls.push("flex-" + verticalAlign);
    cls.push("flex-" + horizontalAlign);
    if (className) cls.push(className);

    return (
        <div className={cls.join(" ")}>{children}</div>
    )
}
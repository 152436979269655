import {RemixIcon} from "../components/common/RemixIcon";
import {Flex} from "../components/common/Flex";
import {Divider} from "../components/common/Divider";
import {useHeader} from "../context/HeaderProvider";
import {useEffect} from "react";
import {useI18n} from "../context/LocaleProvider";
import {I18n} from "../i18n/I18n";

export const ErrorPage = () => {
    const {setTitle} = useHeader();
    const {locale} = useI18n();
    const i18n = I18n[locale];
    useEffect(() => {
        setTitle(i18n.pageNotFound);
        document.body.style.overflow = "hidden";
    }, [i18n, setTitle]);
    return (
        <div className={"error-page"}>
            <div>
                <div className={"p-l"}>
                    <Flex isVertical={true}>
                        <RemixIcon name={"error"} style={{fontSize: "96px"}}/>
                        <h1 className={"error-title"}>404</h1>
                        <Divider/>
                        <h3 className={"error-subtitle"}>{i18n.pageNotFound}</h3>
                        <p className={"text-caption error-caption"}>{i18n.pageNotFoundDesc}</p>
                    </Flex>
                </div>
            </div>
        </div>
    );

}
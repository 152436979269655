import {pageSize} from "./defaults";
import {SortProps} from "../models/common";

export const authHeader = (token: string) => {
    return {"Authorization": "Bearer " + token};
}

export function getPaginatedData<T>(data: T[], page?: number): T[] {

    if (!page) {
        page = 1;
    }
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, data.length);
    return data.slice(startIndex, endIndex);
}


export function getIntParam(name: string, defaultValue = 1): number {
    let page = getQueryParams(name) || `${defaultValue}`;
    try {
        let parsed = parseInt(page);
        if (parsed <= 1) return 1;
        return parsed;
    } catch (e) {
        return 1;
    }
}

export function getQueryParams(key: string): string {
    let params = new URLSearchParams(window.location.search);
    return params.get(key) ?? "";
}

export function getSort(sortBy: SortProps): string {
    let sort = sortBy.sortBy;
    let sortOrder = sortBy.sortOrder;
    if (sort && sortOrder === -1) sort = "-" + sort;
    return sort ?? "";
}

export function getSortFromQuery(): SortProps | null {
    let sort = getQueryParams("sort");
    if (sort) {
        if (sort.startsWith("-")) {
            return {sortBy: sort.substring(1), sortOrder: -1};
        } else {
            return {sortBy: sort, sortOrder: 1};
        }
    }
    return null
}

export function updateUrlParam(key: string, value: string, deletePage?: boolean) {
    let params = new URLSearchParams(window.location.search);
    if (value === null) params.delete(key)
    else params.set(key, value);
    if (deletePage) {
        params.delete("page");
    }
    let paramsList: string[] = [];
    params.forEach((value, key) => {
        paramsList.push(`${key}=${value}`);
    });
    return "?" + paramsList.join("&");
}

export function debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
    let timerId: ReturnType<typeof setTimeout>;
    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
        const context = this;
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}


export function getWeekday(dateString: string) {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const weekdayIndex = date.getDay();
    return weekdays[weekdayIndex];
}

export function getInitials(firstName?: string, lastName?: string): string {
    let value = "";
    if (firstName) value = firstName.substring(0, 1);
    if (lastName) value += lastName.substring(0, 1);
    return value;
}


export function getName(firstName?: string, lastName?: string): string {
    return [firstName, lastName].filter(e => e !== undefined).join(" ");
}


declare global {
    interface String {
        format: (...params: string[]) => string;
        formatDate: () => string;
    }
}
/*eslint-disable*/
String.prototype.format = function (...params: string[]): string {
    let str = this;
    params.forEach(e => {
        str = this.replace("{}", e);
    })
    return str.toString();
}

/*eslint-disable*/
String.prototype.formatDate = function (): string {
    const currentTime = new Date();
    let dateStr = this.toString();

    let givenTime = new Date();
    try {
        givenTime = new Date(dateStr);
    } catch (e) {
        return "-";
    }


    if (currentTime.toDateString() === givenTime.toDateString()) {
        return `Today, ${Intl.DateTimeFormat(undefined, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).format(givenTime)}`;
    }
    let yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    if (yesterday.toDateString() === givenTime.toDateString()) {
        return `Yesterday, ${Intl.DateTimeFormat(undefined, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).format(givenTime)}`;
    }
    return `${Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: '2-digit',
        month: 'short',
        day: 'numeric',
        hour12: true,
    }).format(givenTime)}`;

}
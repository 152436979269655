import React, {createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {RemixIcon} from "../components/common/RemixIcon";
import {IconsSize, typeMap} from "../utils/defaults";
import {State} from "../utils/types";

type SnackbarProps = {
    message: string;
    type?: State;
    onClose?: () => void;
}

type SnackbarContextType = {
    showSnackbar: (props: SnackbarProps) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

export type SnackbarProviderProps = {
    children: ReactNode;
}
export const SnackbarProvider = ({children}: SnackbarProviderProps) => {
    const [snackbarData, setSnackbarData] = useState<SnackbarProps | null>(null);

    const showSnackbar = (props: SnackbarProps) => {
        setSnackbarData(props);
    };
    let timeout = useRef<NodeJS.Timeout | null>(null);
    const _onClose = useCallback(() => {
        setSnackbarData(null);
        if (snackbarData?.onClose) {
            snackbarData?.onClose();
        }
        if (timeout.current) clearTimeout(timeout.current);
    }, [snackbarData]);


    useEffect(() => {
        timeout.current = setTimeout(() => {
            _onClose();
        }, 2000);
        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        }
    }, [_onClose])


    return (
        <SnackbarContext.Provider value={{showSnackbar}}>
            {children}
            {snackbarData &&
                <Snackbar onClose={_onClose} message={snackbarData.message} type={snackbarData.type}/>}
        </SnackbarContext.Provider>
    );
};


const Snackbar = ({message, type = "normal", onClose}: SnackbarProps) => {

    return (<div className={"snackbar-wrapper"}>
        <div className={"snackbar flex gap-normal flex-middle " + type}>
            <div className={"icon"}>
                {typeMap[type]}
            </div>
            <span className={"flex-fill"}>{message}</span>
            <button onClick={onClose} className={"btn-transparent"}>
                <RemixIcon style={{fontSize: IconsSize.s}} className={"text-alt"} name={"close"}/>
            </button>
        </div>
    </div>);
}
export const RepoConfig = {
    baseURL: {
        development: "http://localhost:8888/v1/admin",
        production: "https://api.wiselyu.com/v1/admin",
        test: ""
    },
    getURL: function (path: string, parameters?: { [key: string]: string }) {
        let url = this.baseURL[process.env.NODE_ENV] + path
        if (parameters) {
            let params: string[] = [];
            Object.keys(parameters).forEach((v, _) => {
                params.push(v + "=" + parameters[v]);
            });
            return url + "?" + params.join("&");
        }
        return url;
    }
}
export const Endpoints = {
    dashboard: "/dashboard",
    institutions: "/institutions",
}
export const en = {
    search: "Search",
    sort: "Sort",
    filter: "Filter",
    email: "Email",
    pageNotFound: "Page not found!",
    pageNotFoundDesc: "The page you are trying to access does not exist.",
    theme: "Theme",
    light: "Light",
    dark: "Dark",
    systemDefault: "System default",
    language: "Language",
    english: "English",
    hindi: "हिन्दी",
    role: "Role",
    admin: "Admin",
    user: "User",
    active: "Active",
    inActive: "Inactive",
    loading: "Loading...",
    pleaseWait: "Please wait",
    call: "Call",
    sendMail: "Send mail",
    sidebar: {
        general: "General",
        system: "System",
        reports: "Reports",
        dashboard: "Dashboard",
        institutions: "Institutions",
        subscriptions: "Subscriptions",
        userManagement: "User management",
        settings: "Settings",
        helpCenter: "Support",
        users: "Users",
    },
    institution: {
        changeStatus: "Change status",
        edit: "Edit",
    }
}
import {RemixIcon} from "../components/common/RemixIcon";

export const Dimens = {
    dimenXS: 4,
    dimenS: 8,
    dimenM: 12,
    dimenL: 16,
    dimenXL: 20,
    dimenXXL: 20,
}

export const IconsSize = {
    xs: 12,
    s: 16,
    m: 24,
    l: 32,
    xl: 48,
    xxl: 64,
}

export const pageSize: number = 10;
export const debounceDelay: number = 800;


export const typeMap = {
    "normal": <RemixIcon name={"information"}/>,
    "success": <RemixIcon name={"checkbox-multiple"}/>,
    "warning": <RemixIcon name={"alert"}/>,
    "error": <RemixIcon name={"error-warning"}/>,
    "info": <RemixIcon name={"information"}/>,
};
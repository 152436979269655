import {createContext, ReactNode, useContext, useEffect, useState} from "react";

export type Locale = "en";
export type LocaleData = {
    locale: Locale
    setLocale: (value: Locale) => void
}

const LocaleContext = createContext<LocaleData | undefined>(undefined);

export type LocaleProviderProps = {
    children: ReactNode
}
export const LocaleProvider = ({children}: LocaleProviderProps) => {
    const [locale, setLocale] = useState<Locale>("en");
    const changeLocale = (value: Locale) => {
        setLocale(value);
        localStorage.setItem("locale", value);
    }

    useEffect(() => {
        let localLang = localStorage.getItem("locale");
        setLocale((localLang as (Locale | null)) ?? "en");
    }, []);


    return (
        <LocaleContext.Provider value={{locale, setLocale: changeLocale}}>{children}</LocaleContext.Provider>
    );
}

export const useI18n = () => {
    const context = useContext(LocaleContext);
    if (!context) throw Error("LocaleContext must be the parent component!");
    return context;
}
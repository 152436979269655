import fetchBuilder from "fetch-retry-ts";
import {RepoConfig} from "../repo/repo_config";

export type NetworkingProps = {
    headers?: { [key: string]: string };
    extraHeaders?: { [key: string]: string };
    body?: any;
    queryParameters?: { [key: string]: any };
    statusOnly?: boolean;
    isMultipartMixed?: boolean;
}

const HttpMethod = {
    Get: 'GET',
    Post: 'POST',
    Head: 'HEAD',
    Patch: 'PATCH',
    Put: 'PUT',
    Delete: 'DELETE',
}


class ApiError {
    code: number;
    message: any;

    constructor(code: number, message: any) {
        this.code = code;
        this.message = message;
    }
}

export class Networking {
    defaultHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };
    fetch = fetchBuilder(fetch, {retries: 3, retryOn: [419, 500, 503, 504], retryDelay: 300})

    _handleError = async (err: any) => {
        if (err instanceof ApiError) throw err;
        if (err instanceof Response) {
            throw new ApiError(err.status, await err.json());
        }
        if (err instanceof Error) {
            throw new ApiError(400, JSON.stringify(
                {code: "UNKNOWN", message: err.message}
            ));
        }
        if (err instanceof Promise) {
            let error = await err;
            throw new ApiError(400, error);
        }
        if (err instanceof Object) {
            throw new ApiError(400, JSON.stringify(err));
        }
        throw new ApiError(400, JSON.stringify(err));
    }


    _handleResponse = (res: Response, statusOnly?: boolean, isMultipartMixed?: boolean) => {
        if (statusOnly && res.ok) return true;
        if (res.ok) return res.json();
        if (res.status === 401) {
            throw new ApiError(401, JSON.stringify({code: "UNAUTHORIZED", message: "Unauthorized!"}));
        }
        if (res.status === 403) {
            throw new ApiError(403, JSON.stringify({
                code: "FORBIDDEN",
                message: "You are forbidden to access this page!"
            }));
        }
        throw res.json();
    }

    async get<T>(path: string, {
        headers,
        extraHeaders,
        statusOnly,
        queryParameters,
    }: NetworkingProps): Promise<T> {
        let url = RepoConfig.getURL(path, queryParameters);
        let customHeaders = {...this.defaultHeaders, ...extraHeaders};
        return this.fetch(url, {
            method: HttpMethod.Get,
            headers: headers ?? customHeaders,
        }).then((res: Response) => this._handleResponse(res, statusOnly)).then((data: any) => data).catch(this._handleError);
    }


    async post<T>(path: string, {
        headers,
        extraHeaders,
        body,
        statusOnly,
        queryParameters,
        isMultipartMixed,
    }: NetworkingProps): Promise<T> {
        let url = RepoConfig.getURL(path, queryParameters);
        let customHeaders = {...this.defaultHeaders, ...extraHeaders};
        return this.fetch(url, {
            method: HttpMethod.Post,
            headers: headers ?? customHeaders,
            body: body,
        }).then((res: Response) => this._handleResponse(res, statusOnly, isMultipartMixed)).then((data: any) => data).catch(this._handleError);
    }

    async head<T>(path: string, {
        headers,
        extraHeaders,
        statusOnly,
        queryParameters
    }: NetworkingProps): Promise<T> {
        let url = RepoConfig.getURL(path, queryParameters);
        let customHeaders = {...this.defaultHeaders, ...extraHeaders};
        return this.fetch(url, {
            method: HttpMethod.Head,
            headers: headers ?? customHeaders,
        }).then((res: Response) => this._handleResponse(res, statusOnly)).then((data: any) => data).catch(this._handleError);
    }

    async delete<T>(path: string, {
        headers,
        extraHeaders,
        body,
        statusOnly,
        queryParameters
    }: NetworkingProps): Promise<T> {
        let url = RepoConfig.getURL(path, queryParameters);
        let customHeaders = {...this.defaultHeaders, ...extraHeaders};
        return this.fetch(url, {
            method: HttpMethod.Delete,
            headers: headers ?? customHeaders,
            body: body,
        }).then((res: Response) => this._handleResponse(res, statusOnly)).then((data: any) => data).catch(this._handleError);
    }

    async put<T>(path: string, {
        headers,
        extraHeaders,
        body,
        statusOnly,
        queryParameters
    }: NetworkingProps): Promise<T> {
        let url = RepoConfig.getURL(path, queryParameters);
        let customHeaders = {...this.defaultHeaders, ...extraHeaders};
        return this.fetch(url, {
            method: HttpMethod.Put,
            headers: headers ?? customHeaders,
            body: body,
        }).then((res: Response) => this._handleResponse(res, statusOnly)).then((data: any) => data).catch(this._handleError);
    }

    async patch<T>(path: string, {
        headers,
        extraHeaders,
        body,
        statusOnly,
        queryParameters
    }: NetworkingProps): Promise<T> {
        let url = RepoConfig.getURL(path, queryParameters);
        let customHeaders = {...this.defaultHeaders, ...extraHeaders};
        return this.fetch(url, {
            method: HttpMethod.Patch,
            headers: headers ?? customHeaders,
            body: body,
        }).then((res: Response) => this._handleResponse(res, statusOnly)).then((data: any) => data).catch(this._handleError);
    }

}
import {Route, Routes} from "react-router-dom";
import {ErrorPage} from "./pages/ErrorPage";
import {Dashboard} from "./pages/Dashboard";
import {Settings} from "./pages/Settings";
import {Institutions} from "./pages/institutions/Institutions";

export const RouteWrapper = {
    routes: <Routes>
        <Route path={"/"} element={<Dashboard/>}/>
        <Route path={"/institutions"} element={<Institutions/>}/>
        <Route path={"/settings"} element={<Settings/>}/>
        <Route path={"*"} element={<ErrorPage/>}/>
    </Routes>
}
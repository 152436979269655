import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import "../../css/Form.css";
import {Card} from "./Card";
import {OverlayPanel} from "primereact/overlaypanel";
import {Alert} from "./Alert";
import {SelectFieldProps, SelectItem, SelectRef} from "./SelectField";
import {RemixIcon} from "./RemixIcon";
import {Indicator} from "./Indicator";

export const IconedSelect = forwardRef<SelectRef, SelectFieldProps>(((props, ref) => {
    const {
        defaultValue,
        defaultItems,
        className,
        onChange,
        disabled,
        readonly,
        validator,
    } = props;
    const [value, setVal] = useState<any>(null);
    const [item, setItem] = useState<SelectItem[]>([]);
    const [overlayShowing, setOverlayShowing] = useState(false);
    const [error, setError] = useState<string | null>();
    const overlayRef = useRef<OverlayPanel>(null);
    const onSelect = (value: any) => {
        setVal(value);
        if (onChange) onChange(value);
        setError(handleValidator(value));
        overlayRef.current?.hide();
    }


    const getValue = () => value;

    const setValue = (val?: SelectItem) => {
        setVal(val);
    }

    const setExternalError = (error?: string) => {
        setError(error);
    }

    const setItems = (items: SelectItem[]) => {
        setItem(items);
        setError(null);
    }

    useImperativeHandle(ref, () => ({validate, getValue, setValue, setExternalError, setItems}));

    const validate = (): boolean => {
        let err = handleValidator(value);
        setError(err);
        return err != null;
    };

    useEffect(() => {
        if (defaultValue) {
            setVal(defaultValue);
        }
        if (defaultItems) {
            setItem(defaultItems);
        }
        setError(null);
    }, [defaultValue, defaultItems]);

    const handleValidator = (value?: SelectItem): string | null => {
        if (validator) {
            return validator(value);
        }
        return null;
    }


    return (<>

            <div onClick={(event) => {
                // setWidth(event.currentTarget.clientWidth);
                overlayRef.current?.toggle(event);
            }} data-readonly={readonly} data-disabled={disabled}
                 className={"iconed-select" + (error ? " error" : "") + (value ? " has-value" : "") + (overlayShowing ? " focused" : "") + (className ? " " + className : "")}>
                <span className={"form-select-value"}>{value?.title}</span>
                <RemixIcon size={"small"} name={"arrow-down-s"}/>
            </div>

            <OverlayPanel
                onShow={() => setOverlayShowing(true)}
                onHide={() => setOverlayShowing(false)}
                ref={overlayRef}>
                <Card className={"form-select-dropdown iconed"}>
                    {item.length === 0 ? <Alert type={"warning"} text={"No options to select"}/> : item.map(e => (
                        <div key={`${e.value}`} className={"form-select-dropdown-item-wrapper"}>
                            <div className={"form-select-dropdown-item" + (value?.value === e.value ? " active" : "")}
                                 onClick={() => onSelect(e)}>
                                <Indicator size={"small"}/>
                                <div>{e.title}</div>
                            </div>
                        </div>))}
                </Card>
            </OverlayPanel>
        </>
    )
}));
import {createContext, ReactNode, useContext, useEffect, useState} from "react";


export type HeaderData = {
    title: string
    setTitle: (value: string) => void
}

const HeaderContext = createContext<HeaderData | undefined>(undefined);

export type HeaderProviderProps = {
    children: ReactNode
}
export const HeaderProvider = ({children}: HeaderProviderProps) => {

    const [title, updateTitle] = useState<string>("EduHub");
    const setTitle = (value: string) => {
        updateTitle(value);
    }
    useEffect(() => {
        document.title = `${title} | Admin`;
    }, [title]);

    return (
        <HeaderContext.Provider value={{title, setTitle}}>{children}</HeaderContext.Provider>
    );
}

export const useHeader = () => {
    const context = useContext(HeaderContext);
    if (!context) throw Error("HeaderContext must be the parent component!");
    return context;
}
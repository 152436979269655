export const SortNone = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={"18px"} height={"18px"} viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 8H8.001L8 20H6V8H2L7 3L12 8ZM22 16L17 21L12 16H16V4H18V16H22Z"></path>
        </svg>
    );
}

export const SortAsc = () => {
    return (
        <svg className={"asc"} xmlns="http://www.w3.org/2000/svg" width={"18px"} height={"18px"} viewBox="0 0 24 24"
             fill="currentColor">
            <path
                d="M4.86885 11H2.6665L6 3H8L11.3334 11H9.13113L8.7213 10H5.27869L4.86885 11ZM6.09836 8H7.90163L7 5.8L6.09836 8ZM18.9999 16V3H16.9999V16H13.9999L17.9999 21L21.9999 16H18.9999ZM10.9999 13H2.99992V15H7.85414L2.99992 19V21H10.9999V19H6.14605L10.9999 15V13Z"></path>
        </svg>
    );
}

export const SortDesc = () => {
    return (
        <svg className={"desc"} xmlns="http://www.w3.org/2000/svg" width={"18px"} height={"18px"} viewBox="0 0 24 24"
             fill="currentColor">
            <path
                d="M4.86885 11H2.6665L6 3H8L11.3334 11H9.13113L8.7213 10H5.27869L4.86885 11ZM6.09836 8H7.90163L7 5.8L6.09836 8ZM21.9999 8L17.9999 3L13.9999 8H16.9999V21H18.9999V8H21.9999ZM10.9999 13H2.99992V15H7.85414L2.99992 19V21H10.9999V19H6.14605L10.9999 15V13Z"></path>
        </svg>
    );
}
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Loader} from "../components/common/Loader";

export type Brightness = "light" | "dark";
export type ThemeData = {
    brightness?: Brightness
    setBrightness: (value?: Brightness) => void
}

const ThemeContext = createContext<ThemeData | undefined>(undefined);

export type ThemeProviderProps = {
    children: ReactNode
}
export const ThemeProvider = ({children}: ThemeProviderProps) => {
    const [init, setInit] = useState(false);
    const [theme, setTheme] = useState<Brightness | undefined>(undefined);
    const setBrightness = (value?: Brightness) => {
        if (value) {
            localStorage.setItem("theme", value);
            updateTheme(value);
        } else {
            localStorage.removeItem("theme");
            updateTheme(getSystemBrightness(), true);
        }
    }


    const getSystemBrightness = (): Brightness => {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
    }

    const updateTheme = (theme?: string, systemTheme = false) => {
        document.documentElement.setAttribute("data-theme", theme ? theme : "");
        if (systemTheme) {
            setTheme(undefined);
        } else {
            setTheme(theme as Brightness);
        }
        setInit(true);
    }
    useEffect(() => {
        let localTheme = localStorage.getItem("theme");
        updateTheme(localTheme || getSystemBrightness(), localTheme == null);
    }, []);

    useEffect(() => {
        const handleColorSchemeChange = (e: MediaQueryListEvent) => {
            let localTheme = localStorage.getItem("theme");
            const preferredBrightness = e.matches ? "dark" : "light";
            if (localTheme === null) {
                updateTheme(preferredBrightness, true);
            }
        };

        const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
        colorSchemeQuery.addEventListener("change", handleColorSchemeChange);

        return () => {
            colorSchemeQuery.removeEventListener("change", handleColorSchemeChange);
        };
    }, []);

    if (!init) return (<Loader size={"large"}/>);
    return (
        <ThemeContext.Provider value={{brightness: theme, setBrightness}}>{children}</ThemeContext.Provider>
    );
}

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) throw Error("ThemeContext must be the parent component!");
    return context;
}
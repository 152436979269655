import {ReactNode} from "react";
import {Loader} from "./Loader";

export type ButtonProps = {
    btnStyle?: "primary" | "white" | "secondary" | "accent" | "danger";
    onClick?: (e: any) => void
    leading?: ReactNode
    text: any
    trailing?: ReactNode
    className?: string
    wide?: boolean;
    widePadded?: boolean;
    alternate?: boolean;
    loading?: boolean;
    loadingText?: string;
    size?: "small" | "normal" | "large";
}
export const Button = ({
                           btnStyle = "primary",
                           size = "normal",
                           onClick,
                           wide,
                           leading,
                           text,
                           trailing,
                           className,
                           loading = false,
                           loadingText,
                           alternate,
                           widePadded,
                       }: ButtonProps) => {
    let cls = [];
    if (btnStyle) cls.push(btnStyle);
    if (className) cls.push(className);
    if (wide) {
        cls.push("btn-block");
        cls.push("flex-fill");
    }
    if (size) cls.push(size);
    if (alternate) cls.push("alt");
    if (widePadded) cls.push("wide-padded");
    return (
        <button disabled={loading} onClick={onClick}
                className={"flex flex-middle gap-small flex-center btn " + cls.join(" ")}>
            {leading && leading}
            {loading ? (
                <span className={"i-flex flex-middle gap-small"}>
                    <Loader size={"tiny"} color={"gray"} placement={"wrap"}/>
                    {loadingText && <span className={"ml-l"}>{loadingText}</span>}
                </span>
            ) : text}
            {trailing && trailing}
        </button>
    );
}
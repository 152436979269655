import {InstitutionListResponse} from "../models/institution_list_response";
import {useState} from "react";
import {I18n} from "../i18n/I18n";
import {useI18n} from "../context/LocaleProvider";
import {Flex} from "../components/common/Flex";
import {Tooltip} from "../components/common/Tooltip";
import {Button} from "../components/common/Button";
import {RemixIcon} from "../components/common/RemixIcon";

export type RegistrationsMenuProps = {
    item: InstitutionListResponse;
    onStatusChanged: (item: InstitutionListResponse) => void;
    onEdit: (item: InstitutionListResponse) => void;
}
export const InstitutionsMenu = ({item, onStatusChanged, onEdit}: RegistrationsMenuProps) => {
    const [showRespond, setShowRespond] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    let i18n = I18n[useI18n().locale];

    const onCloseRespond = (item?: InstitutionListResponse) => {
        setShowRespond(false);
        if (item) onStatusChanged(item);
    }
    const onCloseDiscard = (item?: InstitutionListResponse) => {
        setShowDiscard(false);
        if (item) onEdit(item);
    }

    return (
        <>
            <Flex>
                <Tooltip tooltip={i18n.institution.changeStatus}>
                    <Button
                        className={"action-btn"} onClick={(event) => {
                        setShowRespond(true);
                    }} btnStyle={"secondary"} size={"small"} text={<RemixIcon name={"exchange-2"}/>}/>
                </Tooltip>
                <Tooltip tooltip={i18n.institution.edit}>
                    <Button
                        className={"action-btn"} onClick={(event) => {

                    }} btnStyle={"secondary"} size={"small"} text={<RemixIcon name={"edit-2"}/>}/>
                </Tooltip>
            </Flex>
        </>
    );
}
import {StatsCard} from "../components/stats/StatsCard";
import "../css/Dashboard.css";
import {useHeader} from "../context/HeaderProvider";
import {useEffect, useState} from "react";
import {useI18n} from "../context/LocaleProvider";
import {I18n} from "../i18n/I18n";
import {Card} from "../components/common/Card";
import {DataTable} from "primereact/datatable";
import {Column, ColumnBodyOptions} from "primereact/column";
import {Columns} from "../utils/columns";
import {Flex} from "../components/common/Flex";
import {Expanded} from "../components/common/Expanded";
import {SectionTitle} from "../components/common/SectionTitle";
import {Button} from "../components/common/Button";
import {RemixIcon} from "../components/common/RemixIcon";
import {APILoadState} from "../models/common";
import {DashboardResponse} from "../models/dashboard_response";
import {InstitutionRepo} from "../repo/institution_repo";
import {InstitutionListResponse} from "../models/institution_list_response";
import {Tooltip} from "../components/common/Tooltip";
import {Link, useNavigate} from "react-router-dom";
import {Alert} from "../components/common/Alert";
import {InstitutionsMenu} from "./InstitutionsMenu";

export const Dashboard = () => {
    const {setTitle} = useHeader();
    const {locale} = useI18n();
    const i18n = I18n[locale];
    const [state, setState] = useState<APILoadState<DashboardResponse>>({status: "idle"});
    const navigate = useNavigate();

    useEffect(() => {
        setTitle(i18n.sidebar.dashboard);
    }, [setTitle, i18n]);

    useEffect(() => {
        const getData = () => {
            setState({status: "loading"});
            new InstitutionRepo().getDashboard("").then(res => {
                setState({status: "success", data: res});
            }).catch(err => {
                if (err.code === 401) {

                }
                setState({status: "error", error: err.message});
            })
        }
        getData();
    }, []);

    const _getBody = (data: InstitutionListResponse, options: ColumnBodyOptions): any => {
        if (options.field === "actions") return <InstitutionsMenu item={data} onStatusChanged={() => {
        }} onEdit={() => {
        }}/>
        if (options.field === "phone") return <Tooltip tooltip={i18n.call}>
            <Link className={"action-link alt"} to={"tel:" + data.phone}>{data.phone}</Link>
        </Tooltip>;
        if (options.field === "email") return <Tooltip tooltip={i18n.sendMail}>
            <Link className={"action-link alt"} to={"mailto:" + data.email}>{data.email}</Link>
        </Tooltip>;
        if (options.field === "status") return <Flex><Alert size={"small"} type={"warning"} text={"Pending"}/></Flex>;
        let field = options.field as keyof InstitutionListResponse;
        if (options.field === "name") {
            return <Link
                to={""}
                className={"action-link"}>
                {data.name}
            </Link>
        }
        return data[field];
    }


    return (
        <div>
            <div className={"stats-grid gap-normal"}>
                <StatsCard
                    color={"rgba(0,100,203,0.2)"}
                    title={"Institutions"}
                    figure={`${state.data?.total_institutions ?? "-"}`}
                    icon={"building"}/>
                <StatsCard
                    color={"rgba(152,0,76,0.2)"}
                    title={"Colleges"}
                    figure={`${state.data?.total_colleges ?? "-"}`}
                    icon={"school"}/>
                <StatsCard
                    color={"rgba(26,107,76,0.2)"}
                    title={"Users"} figure={`${state.data?.total_users ?? "-"}`}
                    icon={"user-3"}/>
                <StatsCard
                    color={"rgba(0,54,121,0.2)"}
                    title={"Students"} figure={`0`}
                    icon={"graduation-cap"}/>
            </div>
            <Card className={"mt-xl table"}>
                <Flex className={"p-l"}>
                    <Expanded>
                        <SectionTitle title={"Recent registrations"}/>
                    </Expanded>
                    <Button text={"Register"} leading={<RemixIcon name={"quill-pen"}/>}/>
                </Flex>
                <DataTable
                    value={state.data?.recent_institutions}>
                    {Columns.institutions(i18n).map(e =>
                        <Column key={e.field}
                                field={e.field}
                                body={_getBody}
                                header={e.name}/>)}
                </DataTable>
                <Flex className={"p-l"}>
                    <Expanded/>
                    <Button onClick={() => {
                        navigate("/institutions");
                    }} btnStyle={"secondary"} text={"See all"} trailing={<RemixIcon name={"arrow-right-s"}/>}/>
                </Flex>
            </Card>
        </div>
    )
}
export const Columns = {
    institutions: function (i18n: any) {
        return [
            {field: "name", name: "Name", sort: true},
            {field: "phone", name: "Phone"},
            {field: "email", name: "Email"},
            {field: "address", name: "Address", sort: true},
            {field: "no_of_colleges", name: "# of colleges", sort: true},
            {field: "status", name: "Status"},
            {field: "actions", name: "Actions"},
        ]
    }
}
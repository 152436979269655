import {createContext, useContext, useEffect, useState} from "react";
import {ChildrenNodeProps} from "../utils/types";
import {Local} from "../utils/local";
import {useNavigate} from "react-router-dom";
import {Role, User} from "../models/user";


export type AuthData = {
    authToken: string | null;
    updateAuthToken: (value: string | null) => void;
    user: User | null;
    updateUser: (value: User | null) => void;
    roles: Role[];
    updateRoles: (value: Role[]) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthData | undefined>(undefined);

export const AuthProvider = ({children}: ChildrenNodeProps) => {
    let local = new Local();
    const [authToken, setAuthToken] = useState<string | null>(local.getAuthToken());
    const [user, setUser] = useState<User | null>(local.getUser());
    const [roles, setRoles] = useState<Role[]>(local.getRoles());
    const navigate = useNavigate()

    /*eslint-disable*/
    useEffect(() => {
        const path = window.location.pathname;
        const isLoginPage = path === "/login" || path === "/register";
        // if (!authToken && !isLoginPage) {
        //     navigate("/login");
        //     navigate(0);
        // }
    }, []);

    const updateAuthToken = (value: string | null) => {
        setAuthToken(value);
    }


    const updateUser = (value: User | null) => {
        setUser(value);
    }

    const updateRoles = (value: Role[]) => {
        setRoles(value);
    }

    const logout = () => {
        localStorage.clear();
        navigate("/login", {replace: true});
        navigate(0);
    }

    return (
        <AuthContext.Provider value={{
            authToken,
            updateAuthToken,
            user,
            updateUser,
            roles,
            updateRoles,
            logout,
        }}>{children}</AuthContext.Provider>
    );
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw Error("AuthContext must be the parent component!");
    return context;
}
import React, {useState} from 'react';
import "./css/Sidebar.css"
import './App.css';
import {Sidebar} from "./components/base/Sidebar";
import {Header} from "./components/base/Header";
import {BrowserRouter} from "react-router-dom";
import {RouteWrapper} from "./RouteWrapper";
import {LocaleProvider} from "./context/LocaleProvider";
import {ThemeProvider} from "./context/ThemeProvider";
import {SnackbarProvider} from "./context/SnackbarProvider";
import {HeaderProvider} from "./context/HeaderProvider";
import {AuthProvider} from "./context/AuthProvider";

const App = (): React.JSX.Element => {

    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    return (
        <SnackbarProvider>
            <HeaderProvider>
                <LocaleProvider>
                    <ThemeProvider>
                        <BrowserRouter>
                            <AuthProvider>
                                <Sidebar collapsed={sidebarCollapsed}/>
                                <Header onMenuTap={() => {
                                    setSidebarCollapsed(!sidebarCollapsed);
                                }}/>
                                <main>
                                    <div>
                                        {RouteWrapper.routes}
                                    </div>
                                    <div onClick={() => {
                                        setSidebarCollapsed(prevState => !prevState);
                                    }} className={"mask"}></div>
                                </main>
                            </AuthProvider>
                        </BrowserRouter>
                    </ThemeProvider>
                </LocaleProvider>
            </HeaderProvider>
        </SnackbarProvider>
    );
}

export default App;

import React from "react";
import {RemixIcon} from "../common/RemixIcon";
import logo from "../../logo.png";
import {SidebarSectionTitle} from "./SidebarSectionTitle";
import {useI18n} from "../../context/LocaleProvider";
import {I18n} from "../../i18n/I18n";
import {Link, useLocation} from "react-router-dom";

export type SidebarProps = {
    collapsed: boolean
}

export const Sidebar = ({collapsed}: SidebarProps) => {
    const {locale} = useI18n();
    const i18n = I18n[locale];
    const {pathname} = useLocation();
    const isActive = (path: string): string => {
        if (path === pathname) {
            return "active";
        }
        return ""
    }

    return (
        <aside className={"sidebar" + (collapsed ? " collapsed" : "")}>
            <div className={"sidebar-header"}>
                <img className={"logo"} src={logo} alt={""}/>
                <div className={"logo-text"}>edu care</div>
            </div>
            <div className={"sidebar-body"}>
                <div className={"sidebar-section"}>
                    <SidebarSectionTitle title={i18n.sidebar.general}/>
                    <div className={"sidebar-links"}>
                        <Link className={isActive("/")} to={"/"} title={i18n.sidebar.dashboard}>
                            <RemixIcon name={"home-6"}/>
                            <span>{i18n.sidebar.dashboard}</span>
                        </Link>

                        <Link className={isActive("/institutions")} to={"/institutions"}
                              title={i18n.sidebar.institutions}>
                            <RemixIcon name={"graduation-cap"}/>
                            <span>{i18n.sidebar.institutions}</span>
                        </Link>

                        <Link className={isActive("/subscriptions")} to={"/subscriptions"}
                              title={i18n.sidebar.subscriptions}>
                            <RemixIcon name={"rss"}/>
                            <span>{i18n.sidebar.subscriptions}</span>
                        </Link>

                        <Link className={isActive("/users")} to={"/users"}
                              title={i18n.sidebar.userManagement}>
                            <RemixIcon name={"user-3"}/>
                            <span>{i18n.sidebar.userManagement}</span>
                        </Link>

                    </div>

                    <SidebarSectionTitle title={i18n.sidebar.reports}/>
                    <div className={"sidebar-links"}>
                        <Link className={isActive("/reports/institutions")} to={"/reports/institutions"}
                              title={i18n.sidebar.institutions}>
                            <RemixIcon name={"bar-chart-box"}/>
                            <span>{i18n.sidebar.institutions}</span>
                        </Link>

                        <Link className={isActive("/reports/subscriptions")} to={"/reports/subscriptions"}
                              title={i18n.sidebar.subscriptions}>
                            <RemixIcon name={"bubble-chart"}/>
                            <span>{i18n.sidebar.subscriptions}</span>
                        </Link>

                        <Link className={isActive("/reports/users")} to={"/reports/users"}
                              title={i18n.sidebar.users}>
                            <RemixIcon name={"line-chart"}/>
                            <span>{i18n.sidebar.users}</span>
                        </Link>
                    </div>

                    <SidebarSectionTitle title={i18n.sidebar.system}/>
                    <div className={"sidebar-links"}>
                        <Link className={isActive("/settings")} to={"/settings"} title={i18n.sidebar.settings}>
                            <RemixIcon name={"tools"}/>
                            <span>{i18n.sidebar.settings}</span>
                        </Link>

                        <Link className={isActive("/help")} to={"/help"}
                              title={i18n.sidebar.helpCenter}>
                            <RemixIcon name={"hand-heart"}/>
                            <span>{i18n.sidebar.helpCenter}</span>
                        </Link>

                    </div>
                </div>

            </div>
        </aside>
    );
}

import {CSSProperties} from "react";
import {Size} from "../../utils/types";
import 'remixicon/fonts/remixicon.css';

type RemixIconType = "line" | "fill";
export type RemixIconProps = {
    name: string;
    type?: RemixIconType;
    className?: string;
    style?: CSSProperties;
    size?: Size;
}
export const RemixIcon = ({name, type = "fill", size, className, style}: RemixIconProps) => {
    let cls: (RemixIconType | string)[] = ["remix-icon", "ri-" + name + "-" + type];
    if (className) {
        cls.push(className);
    }
    if (size) cls.push(size);
    return (<span style={style} className={cls.join(" ")}/>);
}